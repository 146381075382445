<template>
  <div>
    <Hero heading="Une nouvelle façon de recharger">
      <template slot="image">
        <picture>
          <img src="@/assets/images/PlugAndCharge/hero.jpg" alt="Plugging in an electric vehicle charging connector" />
        </picture>
      </template>
    </Hero>

    <TwoColumnContentBlock
      heading="Brancher et recharger – "
      headingLineTwo=" C’est vraiment très simple"
      headlineDisplayClass="headline3 plug-and-charge"
      :maxWidth="1000"
    >
      <p class="top-description">
        Cette technologie de pointe simplifie le processus de recharge pour les véhicules admissibles. Après une
        inscription rapide, les propriétaires qui bénéficient
        <router-link class="link" :to="{ name: 'premium-offers-fr' }">d’avantages de recharge</router-link> d’Electrify
        Canada et possèdent un véhicule électrique (VE) admissible peuvent profiter de la commodité de la recharge sans
        anicroche. Vous n’avez qu’à vous présenter, brancher et laisser la fonctionnalité Brancher et recharger faire le
        reste.
      </p></TwoColumnContentBlock
    >

    <section class="just-plug-and-charge bg-gray">
      <div class="l-three-col tricard-container">
        <Card heading="Aucun écran à toucher" headlineLevel="h3" isSmall headlineDisplayClass="headline2">
          <template #image>
            <img loading="lazy" src="@/assets/images/PlugAndCharge/x.png" alt="An X." />
          </template>
        </Card>
        <Card
          heading="Pas besoin de glisser la carte de crédit"
          headlineLevel="h3"
          isSmall
          headlineDisplayClass="headline2"
        >
          <template #image>
            <img loading="lazy" src="@/assets/images/PlugAndCharge/x.png" alt="An X." />
          </template>
        </Card>
        <Card
          heading="Il vous suffit de brancher et de recharger"
          headlineLevel="h3"
          isSmall
          headlineDisplayClass="headline2"
        >
          <template #image>
            <img loading="lazy" src="@/assets/images/PlugAndCharge/check.png" alt="A checkmark." />
          </template>
        </Card>
      </div>
    </section>

    <section class="l-one">
      <div class="l-one__col">
        <h2 class="headline3">Mode d’emploi</h2>
        <p>
          Après une inscription rapide, lorsque vous branchez votre VE à une borne de recharge d’Electrify Canada, la
          fonctionnalité Brancher et recharger trouve automatiquement les renseignements liés à votre compte et lance la
          session.
        </p>
        <br />
        <h2 class="headline3">Véhicules admissibles à la fonctionnalité Brancher et recharger</h2>
        <p>
          Confirmez que votre véhicule est admissible en consultant la liste ci-dessous
          <reference-link :num="1">
            Communiquez avec le fabricant de votre véhicule pour obtenir les instructions d’admissibilité et
            d’activation.</reference-link
          >. Bien que toutes les bornes de recharge d’Electrify Canada soient compatibles avec la fonctionnalité
          Brancher et recharger, tous les véhicules ne le sont pas.
        </p>
        <div class="tip">
          <img loading="lazy" src="@/assets/images/PlugAndCharge/icon-star.png" alt="" />
          <p>
            Vous pouvez trouver les instructions d’activation dans l’application mobile du fabricant de votre véhicule.
          </p>
        </div>
        <accordion>
          <accordion-item title="BMW">
            <ul>
              <li>i7 (2023-2025)</li>
            </ul>
          </accordion-item>
          <accordion-item title="Lucid">
            <ul>
              <li>Air (2022-2025)</li>
            </ul>
          </accordion-item>
          <accordion-item title="Porsche">
            <ul>
              <li>
                Taycan (2021-2025)<reference-link :num="2">
                  La Porsche Taycan 2021 prend en charge la fonctionnalité Brancher et recharger si le véhicule n’a pas
                  la borne de recharge facultative de 19,2 kW à bord (PR KB4)
                </reference-link>
              </li>
              <li>Macan Electric (2024-2025)</li>
            </ul>
          </accordion-item>
          <accordion-item class="bottom-border" title="Volkswagen">
            <ul>
              <li style="padding-top: 10px">ID.4 (2023-2025)</li>
              <li>ID.Buzz</li>
            </ul>
            <h5>Activer Brancher et recharger dans l’application mobile Electrify Canada</h5>
            <ol class="custom-counter">
              <li>
                Ouvrez l’application mobile Electrify Canada, ouvrez une session et sélectionnez
                <strong>« Compte »</strong> dans le bas de l’écran.
              </li>
              <li>
                Allez dans <strong>« Forfaits »</strong> dans le menu et sélectionnez votre offre Premium
                <reference-link :num="3">
                  étapes d’activation dans l’application Electrify Canada. Vous devez d’abord être inscrit(e) à l’offre
                  Premium.
                </reference-link>
                .
              </li>
              <li>
                Faites défiler vers le bas et sélectionnez
                <strong>« Brancher et recharger, activer maintenant. »</strong>
              </li>
              <li>
                Suivez les instructions de l’application et confirmez
                <strong>« Activer Brancher et recharger. »</strong>
              </li>
              <li>
                Sélectionnez <strong>« OK »</strong> pour activer la fonctionnalité Brancher et recharger. Une fois
                l’activation terminée, vous verrez l’état <strong>« Inscrit »</strong> à côté de votre offre Premium
                dans l’application mobile Electrify Canada.
              </li>
            </ol>
          </accordion-item>
        </accordion>
        <h5 class="l-one l-one__col learn-more">
          <router-link class="learn-more-text" :to="{ name: 'premium-offers-fr' }">
            En savoir plus sur nos offres Premium<arrow class="arrow"></arrow
          ></router-link>
        </h5>
      </div>
    </section>

    <section class="l-one bg-gray">
      <div class="l-one__col">
        <h2 class="headline3">Comment utiliser la fonctionnalité Brancher et recharger</h2>
        <p>
          Une fois activée, notre technologie Brancher et recharger est prête à utiliser à n’importe quelle borne de
          recharge d’Electrify Canada. Il vous suffit de suivre les étapes indiquées ci-dessous.
        </p>
        <ol class="custom-counter">
          <li>
            1. Présentez-vous à une borne de recharge rapide en CC d’Electrify Canada.
            <router-link class="link" :to="{ name: 'locate-charger-fr' }"
              >Trouver la borne de recharge la plus près.</router-link
            >
          </li>
          <li><strong>Branchez. </strong>Branchez le pistolet de recharge à votre véhicule électrique.</li>
          <li>
            <strong>Vérifiez la recharge. </strong> Consultez l’écran de la borne de recharge pour vous assurer que
            votre recharge a commencé.
          </li>
          <li>
            <strong>Détendez-vous. </strong> Pendant que votre véhicule se recharge, surveillez la progression de la
            recharge dans l’application Electrify Canada.
          </li>
        </ol>
      </div>
    </section>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue';
import AccordionItem from '@/components/AccordionItem.vue';
import Arrow from '@/assets/images/Global/arrow.svg';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'getting-started',
  components: { Accordion, AccordionItem, Arrow, Card, Hero, ReferenceLink, TwoColumnContentBlock },
  metaInfo: {
    title: `Plug&Charge | Electrify Canada`,
    meta: [
      {
        name: 'description',
        content: `For eligible vehicles, Electrify Canada's Plug&Charge technology simplifies the charging process. Learn how it works here.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/plug-and-charge/' }],
  },
};
</script>

<style lang="scss" scoped>
.just-plug-and-charge {
  padding-bottom: 40px;
  h2 {
    margin-left: 20px;
    margin-bottom: 60px;
  }
  ::v-deep .card {
    padding-top: 20px;
    padding-bottom: 10px;

    max-width: 250px;
    text-align: center;

    .card__headline {
      color: #030a4f;
    }
  }
  .l-three-col {
    justify-content: space-between;
  }
}
.tricard-container {
  max-width: 600px;
}
.bg-gray {
  background: $c-secondary-background;
}
.l-one {
  padding-top: 80px;
  padding-bottom: 80px;
}

.bottom-border {
  border-bottom: 1px solid $c-border-blue !important;
}

.tip {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  img {
    width: 25px;
    height: 25px;
    order: 1;
    margin: 0;
  }
  p {
    margin: 0;
    margin-left: 15px;
    order: 2;
  }
}

.learn-more {
  padding-top: 20px;
  padding-bottom: 20px;
  .learn-more-text {
    font-weight: 700;
  }
}

.custom-counter li {
  list-style: none;
  counter-increment: step-counter;
  position: relative;
  margin: 20px 0;
  padding-left: 40px;
  min-height: 30px;
}

.custom-counter li:before {
  content: counter(step-counter);
  position: absolute;
  left: 0;

  color: white;
  background: #0a124a;
  display: inline-block;
  text-align: center;
  margin: 0;
  line-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.top-description {
  font-size: 1rem !important;
}
</style>
